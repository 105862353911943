.hero-section {
  color: #fff;
  padding: 50px 0;
  background-size: cover;
  background-position: center;
  display: flex; /* Use Flexbox to align children */
  justify-content: center; /* Center children horizontally */
  align-items: center; /* Center children vertically */
  text-align: center; /* Align text to the center */
  height: 120vh; /* Take full viewport height */
  margin-top: 250px;
  background-color: black;
}

.hero-text h1 {
  margin-top: 3rem;
  font-size: 2.5rem; /* Larger size for the heading */
  font-weight: bold; /* Bolder font for futuristic look */
  margin-bottom: 2rem;
  color: #61dafb; /* A vibrant color for the heading */
}

h2 {
  color: #61dafb; /* A vibrant color for the title */
  font-size: 2rem; /* Size can be adjusted based on design */
  margin-bottom: 1rem;
}

p {
  color: #FFF; /* Maintaining the color scheme */
  font-size: 1.2rem;
  margin-bottom: 2rem;
}

.hero-text p {
  font-size: 1.2rem; /* Increased font size for legibility */
  margin-bottom: 2rem;
}
.hero-section-upward {
  margin-top: -1vh; /* Adjust the value as needed to move the content up */
}

.hero-button {
  background-color: #61dafb; /* Vibrant color for the button */
  border: none;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  transition: background-color 0.3s ease; /* Transition for hover effect */
}

.hero-button:hover,
.hero-button:focus {
  background-color: #4098d7; /* Darker shade for the hover state */
  color: #fff;
}

/* This section might be removed if you no longer need to style the hero image */
.hero-image img {
  max-width: 100%;
  height: auto;
  display: block; /* Ensure image doesn't have extra space below */
}

@keyframes typing {
  from { width: 0; }
  to { width: 100%; }
}

@keyframes blink-caret {
  from, to { border-color: transparent; }
  50% { border-color: white; }
}

.typing-effect {
  overflow: hidden; /* Ensures the content is not visible until it is animated */
  border-right: .15em solid white; /* Creates the caret */
  white-space: nowrap; /* Keeps the text in a single line */
  letter-spacing: .15em; /* Adjust letter spacing */
  animation: 
    typing 3.5s steps(40, end),
    blink-caret .75s step-end infinite;
}

.subtitle-transition {
  opacity: 1;
  transform: translateY(-20px);
  animation: fadeInUp 0.8s ease forwards;
}

@keyframes fadeInUp {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.hero-subtitle {
  font-size: 1.6rem; /* Increased from 1.2rem to 1.5rem */
  margin-bottom: 2rem;
  color: #FFF; /* Optional: change color if needed */
}

.newheading{
  margin-top: 400px;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  background-image: linear-gradient(to bottom, #fefefe, #494982);
  text-align: center;
  z-index: 1;
}