/* General body and margin settings for a clean layout */
body {
    background-color: #000; /* Background set to black */
    color: #fff; /* Text color set to white */
    margin: 0;
    font-family: 'Arial', sans-serif;
}

/* Base styles for the container */
.container-style {
    max-width: 1280px; /* typically 4xl, adjust as needed */
    margin: auto;
    padding-bottom: 40px; /* 40 pixels padding at the bottom */
  }
  
  /* Styles for the main heading */
  .heading-large {
    font-size: 24px; /* Default size */
    color: linear-gradient(to bottom, #ffffff, #a1a1aa);
    font-weight: bold;
    font-family: 'Inter var', sans-serif;
    text-align: center;
    margin-top: -500px;
    margin-bottom: 300px;
  }

  .headings{
    font-size: 50px;
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    background-image: linear-gradient(to bottom, #ffffff, #fc8686);
    text-align: center;
  }

  .ms{
    font-size: 20px;
    color: linear-gradient(to bottom, #ffffff, #a1a1aa);
  }
  
  /* Adjustments for different screen sizes */
  @media (min-width: 768px) {
    .heading-large {
      font-size: 36px; /* Medium screen size */
    }
  }
  
  @media (min-width: 1024px) {
    .heading-large {
      font-size: 64px; /* Large screen size */
    }
  }
  
  /* Styles for the subtext */
  .subheading-text {
    font-size: 16px; /* Default size */
    color: white;
    font-weight: normal;
    font-family: 'Inter var', sans-serif;
    text-align: center;
    margin-top: 16px; /* Margin top for spacing */
  }
  
  @media (min-width: 768px) {
    .subheading-text {
      font-size: 20px; /* Medium screen size */
    }
  }
  

/* Hero Section Styles */
.hero-section1 {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #fff; /* Ensuring text color is white for hero section */
}

.hero-section1 h1 {
    font-size: 48px;
    margin-bottom: 35rem;
}

/* Mission Statement, Team Section, and Contact Form Text Color Adjustments */
.card {
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    transition: transform 0.3s ease-in-out;
    margin: 2rem; /* Added margin around cards for more space */
    background-color: #fff; /* Ensure background is white for better visibility of black text */
}

.card:hover {
    transform: translateY(-5px);
}

.card-img-top {
    width: 100%;
    height: 15vw;
    object-fit: cover;
}

.card-body {
    color: #000; /* Set card body text to black, affecting title and text */
}

.card-title {
    color: #000; /* Card title color to keep it distinct */
}

.card-text {
    color: #000; /* Ensure description text is specifically set to black */
}

.btn-primary {
    background-color: #007bff;
    border: none;
}

.btn-primary:hover {
    background-color: #0056b3;
}
.container.my-6 {
    display: flex;
    flex-direction: column; /* Stacks children vertically */
    justify-content: center; /* Centers children vertically in the container */
    align-items: center; /* Centers children horizontally */
    height: 100%; /* Optional: You might want to adjust the height based on your specific layout */
}

/* Ensuring the form takes up only necessary space */
form {
    width: 100%; /* Adjust based on layout requirements */
    margin-bottom: 0; /* Removes any default bottom margin */
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .hero-section h1 {
        font-size: 32px;
    }
}

.team-card {
    position: relative;
    overflow: hidden;
  }

  .linkedin-icon {
    position: absolute;
  top: 100%; /* Start below the card */
  right: 16px; /* Or any other value to place the icon */
  opacity: 0;
  font-size: 25px;
  color: #000;
  transition: top 0.3s ease, opacity 0.3s ease;
  }

  .team-card:hover .linkedin-icon {
    top: 80%; /* Move the icon up when hovered */
    opacity: 1; /* Make icon visible on hover */
  }
