.meteors-container {
  position: relative;
  width: 100%; /* Use full width for small screens */
  height: 70vh;
  overflow: hidden;
  border: 2px solid #02afff;
  padding-right: 2rem;
  margin: auto; /* Center it horizontally */
  box-sizing: border-box;
  border-radius: 20px;
  background: #00010b;
  transition: all 0.3s ease; /* Smooth transitions on resizing */
}

.text-contents {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* Center both horizontally and vertically */
  text-align: center;
  padding: 2rem;
  z-index: 2;
  width: 100%;
}

.meteor {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 2px;
  height: 2px;
  background: #02afff;
  border-radius: 50%;
  box-shadow: 0 0 10px #fff;
  animation: meteor-effect 5s linear infinite;
  z-index: 1;
}

@media (min-width: 768px) {
  .meteors-container {
      width: 45%; /* Adjust width for larger screens */
      top: 0;
      margin-left: auto;
      margin-right: auto;
  }

  .text-contents p {
      font-size: 1rem; /* Larger font size for larger screens */
  }
}

@media (max-width: 767px) {
  .text-contents p {
      font-size: 0.875rem; /* Smaller font size for smaller screens */
  }
}

@keyframes meteor-effect {
  0% {
      transform: translate(-50%, -50%) rotate(215deg) translateX(0);
      opacity: 1;
  }
  70% {
      opacity: 1;
  }
  100% {
      transform: translate(-50%, -50%) rotate(215deg) translateX(-500px);
      opacity: 0;
  }
}
