/* src/styles/waitlist.css */

/* Import base styles from Tailwind (if you're using Tailwind base styles) */
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

/* Custom styles */
.waitlist-container {
  background-color: #00010b; /* Corresponds to bg-neutral-950 */
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 40rem; /* Corresponds to h-[40rem] */
}

.background-beams {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.content-container {
  position: relative;
  max-width: 36rem; /* Corresponds to max-w-2xl */
  padding: 1rem; /* Corresponds to p-4 */
  z-index: 10;
  width: 100%;
}

.title {
  font-size: 1.125rem; /* Corresponds to text-lg */
  font-weight: bold; /* Corresponds to font-bold */
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  background-image: linear-gradient(to bottom, #e5e7eb, #a1a1aa); /* From neutral-200 to neutral-600 */
  text-align: center;
  margin-bottom: 1rem;
}

@media (min-width: 768px) { /* Corresponds to md: breakpoint in Tailwind */
  .title {
    font-size: 4.5rem; /* Corresponds to text-7xl */
  }
}

.description {
  color: #c7c7c7; /* Corresponds to text-neutral-500 */
  max-width: 48rem; /* Corresponds to max-w-lg */
  margin: 0.5rem auto 1rem; /* Corresponds to mx-auto my-2 */
  font-size: 1rem; /* Corresponds to text-sm */
  text-align: center;
}

.input {
  width: 100%;
  border-radius: 0.5rem; /* Corresponds to rounded-lg */
  border: 1px solid #2d3748; /* Corresponds to border-neutral-800 */
  padding: 0.5rem;
  margin-top: 1rem; /* Corresponds to mt-4 */
  background-color: #1a1c23; /* Corresponds to bg-neutral-950 */
  color: #a1a1aa; /* Placeholder text color - corresponds to text-neutral-700 */
  /* Focus ring styles */
  outline: 2px solid transparent;
  outline-offset: 2px;
  text-align: center;
  margin-bottom: 1rem;
}

.input:focus {
  border-color: #4fd1c5; /* Corresponds to ring-teal-500 */
  box-shadow: 0 0 0 2px #4fd1c5; /* Corresponds to focus:ring-2 focus:ring-teal-500 */
}

/* Standard CSS for placeholder color */
.input::placeholder {
  color: #a1a1aa; /* Corresponds to text-neutral-700 */
}

.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}