.products-section {
  background-color: #00010b;
  color: #fff;
  padding: 50px 0;
  margin-right: 50px;
}

.Coming-soon-title {
  font-size: 3rem;
  font-weight: bold;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  background-image: linear-gradient(to bottom, #ffffff, #a1a1aa);
}

/* General mobile responsiveness */
@media (max-width: 768px) {
  .products-section {
    padding: 20px 10px;
    margin-right: 10px;
  }
  
  .products-title, .Coming-soon-title {
    font-size: 2rem;
  }
  
  .products-description {
    font-size: 1rem;
  }

  .text-content {
    margin-left: 20px;
    text-align: left;
  }
}

/* Specific adjustments for iPhone 12 Pro and similar devices */
@media only screen and (min-device-pixel-ratio: 3) and (min-width: 390px) and (max-width: 390px) {
  .Coming-soon-title, .products-title {
    font-size: 1.5rem;  /* Smaller font size for clarity */
  }

  .text-content {
    left: 50%;
    margin-left: 0;  /* Remove large left margin */
    transform: translateX(-50%);
  }
}

.vortex-demo {
  position: relative;
}

.vortex {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.text-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  color: white;
  text-align: center;
}

.title {
  font-size: 2rem;
  font-weight: bold;
}

.description {
  font-weight: bold;
  margin-top: 1rem;
}
