/* Contact.css */
.contact-container {
  display: flex;
  flex-direction: column; /* Stack elements vertically */
  align-items: center;
  min-height: 100vh;
  background-color: #00010b;
  padding-top: 5rem;/* Full viewport height */
  }

  .contact-title {
    /* Styling for the title */
    margin-bottom: 2rem; /* Adds space between title and form */
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    background-image: linear-gradient(to bottom, #ffffff, #a1a1aa);
  }

  .background-gradient {
    border-radius: 22px;
    max-width: 32rem; /* Equivalent to max-w-sm */
    padding: 1rem; /* Equivalent to p-4 */
    padding: 2.5rem; /* Equivalent to sm:p-10 */
    background-color: #1A202C; /* Light mode */
    color: #FAF9F6;
  }

  .background-gradient-dark {
    background-color: #1A202C; /* dark:bg-zinc-900 */
  }

  .text-base {
    font-size: 1rem; /* Equivalent to text-base */
    margin-top: 1rem; /* Equivalent to mt-4 */
    margin-bottom: 0.5rem; /* Equivalent to mb-2 */
    color: black; /* Light mode text color */
  }

  .text-base-dark {
    color: #E2E8F0; /* dark:text-neutral-200 */
  }

  .text-small {
    font-size: 0.875rem; /* Equivalent to text-sm */
    color: #4A5568; /* Neutral-600 */
  }

  .text-small-dark {
    color: #CBD5E0; /* dark:text-neutral-400 */
  }