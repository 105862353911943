*,
*::before,
*::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

/* Set up the html and body to occupy full viewport height */
html,
body {
    height: 100%;
    width: 100%;
}

/* Set up the main content area to push the footer down */
.main-content {
    display: flex;
    flex-direction: column;
    min-height: 100vh;  /* Replace [Your Footer Height] with the actual height of your footer */
    /* Your main content styles here */
}

/* Footer styles */
.footer {
    background: linear-gradient(135deg, #01041f, #004e92);
    box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2);
    color: #fff;
    text-align: center;
    position: relative;
    margin-top: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.row {
    display: flex;
    align-items: center;
}

/* Ensure that any content within the footer is also not creating extra space */
.footer-content {
padding: 20px;
margin: auto;
max-width: 1200px;
}

.footer-links {
list-style-type: none;
padding-left: 0; /* Removes default padding */ /* Center the links */
}

.footer-links li {
display: inline-block; /* Display links in a line */
margin-right: 15px; /* Spacing between links */
}

.footer-links a {
color: #fff;
text-decoration: none;
transition: color 0.3s ease-in-out;
}

.footer-links a:hover {
color: #a8d1ff; /* Slightly lighter shade for hover effect */
}

.social-icons {
margin-top: 20px; /* Adjust as needed /
text-align: center; / Center the social icons */
}

.social-icons a {
display: inline-block;
margin: 0 10px;
font-size: 24px;
color: #fff; /* White icons to stand out against the dark background /
font-size: 24px; / Adjust size as needed */
text-align: right;
}

.social-icons a:hover {
color: #a8d1ff; /* Slightly lighter shade for hover effect */
}

/* Media query for responsive adjustments */
@media (max-width: 768px) {
.footer-links li {
display: block; 
margin: 5px 0; /* Adjust vertical spacing */
}
.row {
    flex-direction: column;
}

/* Adjust the social icons for mobile */
.social-icons {
    margin-top: 20px;
    text-align: center;
}
}