.gradient-container {
    position: relative;
    padding: 4px;
    width: 20rem;
    display: inline-block;
  }
  
  .gradient-background {
    position: absolute;
    inset: 0;
    border-radius: 0.75rem;
    z-index: 1;
    opacity: 0.6;
    transition: opacity 0.5s ease;
    will-change: transform;
    background-image: radial-gradient(circle farthest-side at 0 100%, #00ccb1, transparent),
      radial-gradient(circle farthest-side at 100% 0, #7b61ff, transparent),
      radial-gradient(circle farthest-side at 100% 100%, #ffc414, transparent),
      radial-gradient(circle farthest-side at 0 0, #1ca0fb, #141316);
  }
  
  .gradient-background.animate {
    animation: gradient-animation 10s ease infinite;
  }
  
  .gradient-container:hover .gradient-background {
    opacity: 1;
  }
  
  .gradient-content {
    position: relative;
    z-index: 10;
  }
  
  @keyframes gradient-animation {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }