.mobile-alert {
    display: none;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    z-index: 1000;
  }
  
  .mobile-alert-content {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #000;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
  }
  
  @media (max-width: 768px) {
    .mobile-alert {
      display: block;
    }
  }

  .mobileheading{
    color: red;
  }
  